/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import useDeviceType from '@/hooks/useDeviceType';
import Filter from './Filter';
import styles from './index.module.scss';

const FilterSort = (props) => {
  const { isDesktop } = useDeviceType();

  return (
    <div className={styles.container}>
      <div>
        <Filter
          title={props.title}
          isDesktop={isDesktop}
        />
      </div>
    </div>
  );
};

FilterSort.propTypes = {
  title: PropTypes.string.isRequired
};
export default FilterSort;
