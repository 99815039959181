import { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import routerPushWithQuery from '@/utils/filters/routerPushWithQuery';
import Chevron from '../Chevron';
import Divider from '../Divider';
import Text from '../Text';
import styles from './index.module.scss';
import FilterOptionSize from '@/components/organisms/Filters/FilterSort/FilterOptionSize';
import FilterOption from '@/components/organisms/Filters/FilterSort/FilterOption';

const SelectFilter = ({ label, options, type, onCheck, multiple, sizes }) => {
  const [close, setClose] = useState(false);
  const [optionSelected, setOptionSelected] = useState(false);
  const ref = useRef(null);
  // const optionRef = useRef(null);
  const { filtersActive, orderBy, filtersOptions } = useSelector(
    (state) => state.products
  );
  const sizesTable = {
    '2XS': 1,
    XXS: 2,
    XS: 3,
    S: 4,
    M: 5,
    L: 6,
    XL: 7,
    XXL: 8,
    '2XL': 9
  };
  const optionsSorted = [...(options || [])].sort((a, b) => {
    if (sizesTable[a.name] && sizesTable[b.name]) {
      return sizesTable[a.name] - sizesTable[b.name];
    }
    // Si solo uno de los nombres está en el objeto de orden, ese va primero
    else if (sizesTable[a.name]) {
      return -1;
    } else if (sizesTable[b.name]) {
      return 1;
    }
    // Si ninguno de los nombres está en el objeto de orden, orden alfabético
    return a.name.localeCompare(b.name);
  });

  useEffect(() => {
    const element = ref.current;
    // const option = optionRef.current;
    if (close) {
      element.style.transition = '0.5s linear';
      element.style.maxHeight = '0';
      // element.style.overflow = 'hidden';
    } else {
      element.style.transition = '0.5s linear';
      element.style.maxHeight = `540px`;
      // element.style.overflowY = 'scroll';
    }
  }, [close, ref.current]);

  useEffect(() => {
    // hacemos el push a la nueva pagina con los filtros aplicados
    if (optionSelected) {
      setOptionSelected(!optionSelected);
      routerPushWithQuery(filtersActive, orderBy, filtersOptions);
    }
  }, [optionSelected, filtersActive]);

  const handleCheck = (slug, selected) => {
    setOptionSelected(!optionSelected);
    onCheck(slug, selected, type, multiple, filtersActive);
  };
  const getOptionsSelected = () => {
    const optionsSelected = optionsSorted.filter(({ selected }) => selected);
    let stringToReturn = '';
    if (optionsSelected.length === 0) return stringToReturn;
    optionsSelected?.forEach(({ slug }) => {
      stringToReturn += `${stringToReturn.length ? '/' : ''}${slug}`;
    });
    return stringToReturn;
  };

  return (
    <li className={styles.container}>
      <button
        className={styles.selectBtn}
        onClick={() => setClose(!close)}
      >
        <div className={styles.textInBtn}>
          <Text
            textColor="white"
            weight="semibold"
          >
            {label}
          </Text>
          <Text
            textColor="white"
            weight="light"
            textStyle={{ fontSize: '.8rem' }}
          >
            {getOptionsSelected()}
          </Text>
        </div>

        <Chevron
          size={28}
          open={!close}
          color="white"
          timeOfRotate="0.5s"
        />
      </button>
      <Divider color="var(--color-grey-lighter)" />
      <ul
        ref={ref}
        className={styles.options}
        style={{
          flexDirection: `${sizes ? 'row' : 'column'}`,
          flexWrap: `${sizes ? 'wrap' : 'nowrap'}`,
          gap: `${sizes ? '15px' : '0px'}`
        }}
      >
        {optionsSorted.map((o) =>
          sizes ? (
            <FilterOptionSize
              key={o.slug}
              onClick={() => handleCheck(o.slug, o.selected, filtersActive)}
              {...o}
            />
          ) : (
            <FilterOption
              key={o.slug}
              onClick={() => handleCheck(o.slug, o.selected, filtersActive)}
              {...o}
            />
          )
        )}
      </ul>
    </li>
  );
};

SelectFilter.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['gender', 'slugs', 'marca', 'deporte', 'talle'])
    .isRequired,
  onCheck: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  sizes: PropTypes.bool
};
SelectFilter.defaultProps = {
  multiple: false,
  sizes: false
};

export default SelectFilter;
