import { BiFilterAlt, BiSortDown } from 'react-icons/bi';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SelectFilter from '@/components/atoms/SelectFilter';
import { ModalFilter } from '@/components/organisms';
import styles from './index.module.scss';
import {
  removeFilter,
  setFilter,
  setOrderBy
} from '@/redux/products/productsReducer';
import newRemovedFilters from '@/utils/newRemovedFilters';
import newAddedFilters from '@/utils/newAddedFilters';
import { Button, Text, Divider } from '@/components/atoms';
import routerPushWithQuery from '@/utils/filters/routerPushWithQuery';

const sortItems = [
  { id: 'mayor_precio', name: 'Precio: Mayor precio', value: 'mayor_precio' },
  { id: 'menor_precio', name: 'Precio: Menor precio', value: 'menor_precio' },
  { id: 'ofertas', name: 'Ofertas', value: 'ofertas' },
  { id: 'lanzamientos', name: 'Lanzamientos', value: 'lanzamientos' }
];

const OptionCheck = ({ selected }) => (
  <div
    style={{
      width: '22px',
      height: '22px',
      borderRadius: '50%',
      border: `solid ${selected ? '5px var(--color-primary)' : '1px white'}  `
    }}
  >
    {selected && (
      <div
        style={{
          width: '100%',
          height: '100%',
          border: '1px solid white',
          borderRadius: '50%'
        }}
      />
    )}
  </div>
);

const Filter = (props) => {
  const { isDesktop } = props;
  const { filtersOptions, filtersActive, pagination, orderBy } = useSelector(
    (state) => state.products
  );
  const modalState = useState({ filter: false, sort: false });
  // const gender = filtersOptions.gender.options.find((f) => f.selected);
  const dispatch = useDispatch();
  // al hacer click en un filtro
  const handleClickOptionFilter = (slug, selected, type, multiple) => {
    // al ejecutar la accion de redux, guardamos el objeto que devuelve dispatch()
    // asi podemos acceder a los filtros activos (incluido el que se hizo click)
    if (!selected) {
      dispatch(setFilter(newAddedFilters(type, multiple, slug, filtersActive)));
    } else {
      dispatch(
        removeFilter(
          newRemovedFilters(type, multiple, slug, { ...filtersActive })
        )
      );
    }
    setTimeout(() => {
      modalState[1]({ filter: false, sort: false });
    }, 300);
  };
  const handleClickOptionSort = (value) => {
    // ejecutamos la accion de redux

    dispatch(setOrderBy(value));
    // hacemos el push a la nueva pagina con los filtros activados
    modalState[1]({ filter: false, sort: false });
    routerPushWithQuery(filtersActive, value, pagination.current);
  };

  const renderOptions = () => (
    <ul
      style={{
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
      }}
    >
      {sortItems.map((o) => (
        <li>
          <button
            onClick={() => handleClickOptionSort(o.value)}
            className={styles.option}
          >
            <OptionCheck selected={orderBy === o.value} />
            <Text
              textColor="white"
              style={{ padding: '10px 0' }}
            >
              {o.name}
            </Text>
          </button>

          <Divider color="grey" />
        </li>
      ))}
    </ul>
  );
  const renderFilterItems = () => {
    const types = {
      sizes: 'talle',
      gender: 'gender',
      brand: 'slugs',
      sport: 'slugs'
    };
    return (
      <ul>
        {Object.keys(filtersOptions)
          .filter((option) => filtersOptions[option].options.length > 0)
          .map((option) => (
            <SelectFilter
              key={filtersOptions[option].name}
              label={filtersOptions[option].name}
              options={filtersOptions[option].options}
              type={types[option] || 'slugs'}
              multiple={option !== 'gender'}
              sizes={option === 'sizes'}
              onCheck={handleClickOptionFilter}
            />
          ))}
      </ul>
    );
  };
  const renderSelects = () => <div>{renderFilterItems()}</div>;

  return (
    <aside className={styles.container}>
      {!isDesktop && (
        <div>
          <div className={styles.filtersMobileBtn}>
            <Button
              id="filerBtn"
              onClick={() => {
                modalState[1]({
                  ...modalState[0],
                  filter: !modalState[0].filter
                });
              }}
              startIcon={
                <BiFilterAlt
                  color="white"
                  fontSize={23}
                />
              }
              label="Filtrar"
              textColor="#fff"
              textStyle={{ fontSize: '1rem' }}
              withOutPadding
              variant="transparent"
            />

            <Divider
              size={40}
              color="var(--color-primary)"
              vertical
            />
            <Button
              id="sortBtn"
              onClick={() => {
                modalState[1]({
                  ...modalState[0],
                  sort: !modalState[0].sort
                });
              }}
              startIcon={
                <BiSortDown
                  color="white"
                  fontSize={23}
                />
              }
              label="Ordenar"
              textColor="#fff"
              textStyle={{ fontSize: '1rem' }}
              withOutPadding
              variant="transparent"
            />
          </div>
          {modalState[0].filter && (
            <ModalFilter
              id="filter"
              modalState={modalState}
              label="Filtrar Por"
            >
              {renderSelects()}
            </ModalFilter>
          )}

          {modalState[0].sort && (
            <ModalFilter
              id="sort"
              modalState={modalState}
              label="Ordenar Por"
            >
              {renderOptions()}
            </ModalFilter>
          )}
        </div>
      )}
      <div className={styles.content}>
        {isDesktop && filtersOptions && renderSelects()}
      </div>
    </aside>
  );
};
OptionCheck.propTypes = {
  selected: PropTypes.bool.isRequired
};
Filter.propTypes = {
  isDesktop: PropTypes.bool
};
Filter.defaultProps = {
  isDesktop: false
};

export default Filter;
